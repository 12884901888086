
































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import {VueEditor} from "vue2-editor";
import Detail from "@/views/app/diagram/Detail.vue";

export default Vue.extend({
    components: {
        VueEditor,
        Detail,
    },
    data() {
        return {
            util,
            userManager,
            dialog: false,
            dialogEdit: false,
            dialogType: false,
            formType: false,
            hideCreate: false,
            loadingCreate: false,
            snackbar: false,
            fails: "",
            name: "",
            form: false,
            dialogDelete: false,
            ruleRequired: (value) => !!value || this.$t("REQUIRED"),
            ruleRequiredArray: (value) =>
                !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
            headers: [
                {text: "ID sơ đồ", value: "id"},
                {text: "Tiêu đề", value: "title"},
                {text: "Người tạo", value: "created_by"},
                {text: "Ngày tạo", value: "created_at"},
                {text: this.$t("ACTIONS"), value: "actions", sortable: false},
            ],
            options: {},
            search: {
                object: "",
                title: "",
                created_by: "",
                id: "",
            },
            loading: false,
            data: [],
            dataCount: 0,
            editedIndex: -1,
            editedItem: {},
            allowDelete: {},
            users: [],
            types: [],
            usersByRoles: [],
            userInfo: userManager.getUserInfo(),
            availableRoleMap: {
                k: this.$t("ROLE_K"),
                xnk: this.$t("ROLE_XNK"),
                kd: this.$t("ROLE_KD"),
                t: this.$t("ROLE_T"),
                kt: this.$t("ROLE_KT"),
                td: this.$t("ROLE_TD"),
                gd: this.$t("ROLE_GD"),
                htkd: this.$t("ROLE_HTKD"),
                cs: "Chính sách",
                kvn: "Kho VN",
                ktt: "Kế toán trưởng",
                mkt: "Marketing",
            },
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Tạo sơ đồ" : this.$t("EDIT");
        },
        availableProgresses() {
            const items = [];
            for (const k in this.availableProgressMap) {
                items.push({
                    text: this.availableProgressMap[k],
                    value: k,
                });
            }
            return items;
        },
        availableRoles() {
            const items = [];
            for (const k in this.availableRoleMap) {
                items.push({
                    text: this.availableRoleMap[k],
                    value: k,
                });
            }
            return items;
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        options: {
            handler() {
                this.initialize();
            },
            deep: true,
        },
        dialogType: {
            handler(val) {
                if (val === false) {
                    this.hideCreate = false
                    this.name = ""
                }
            },
            deep: true,
        },

    },
    methods: {
        async initialize() {
            const filters = [];
            const search = {...this.search}
            for (const searchKey in search) {
                if (search[searchKey]) {
                    if (searchKey === "id") {
                        search[searchKey] = search[searchKey].replace("ST", "");
                    }
                    if (searchKey === "title") {
                        filters.push({
                            key: searchKey,
                            operator: "LIKE",
                            value: `%${search[searchKey]}%`,
                        });
                    } else {
                        filters.push({
                            key: searchKey,
                            operator: "=",
                            value: search[searchKey],
                        });
                    }
                }
            }
            this.loading = true;
            const {items, count} = await apiClient.diagramGetAll({
                options: this.options,
                filters,
            });
            this.data = items
            this.dataCount = count;
            this.loading = false;
        },
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogEdit = true;
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            this.closeDelete();
            await apiClient.diagramDelete(this.editedItem);
            await this.initialize();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeUpdate() {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save() {
            let result;
            const userInfo = {...this.userManager.getUserInfo()};
            if (userInfo.sub) {
                this.editedItem["created_by"] = userInfo.sub;
            }
            if (this.editedIndex > -1) {
                result = await apiClient.diagramUpdate(this.editedItem);
            } else {
                result = await apiClient.diagramCreate(this.editedItem);
            }
            if (!result.errors) {
                this.close();
                await this.initialize();
            } else {
                this.snackbar = true;
                this.fails = result.message;
            }
        },
        async onUpdate() {
            const userInfo = {...this.userManager.getUserInfo()};
            if (userInfo.sub) {
                this.editedItem["created_by"] = userInfo.sub;
            }
            const result = await apiClient.diagramUpdate(this.editedItem);
            if (!result.errors) {
                this.close();
                await this.initialize();
                location.reload()
            } else {
                this.snackbar = true;
                this.fails = result.message;
            }
        },
        getAllUser() {
            this.usersByRoles = [];
            apiClient
                .userGetAll()
                .then((res) => {
                    this.users = [
                        {
                            id: "all",
                            name: "Tất cả",
                        },
                    ].concat(
                        res.items.map((item) => {
                            item.id = String(item.id);
                            return item;
                        })
                    );
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                });
        },
        getName(value, data, keyData = "id", keyName = "name") {
            if (value) {
                if (Array.isArray(data)) {
                    const length = data.length;
                    for (let i = 0; i < length; i++) {
                        if (data[i][keyData] === value) {
                            return data[i][keyName];
                        }
                    }
                }
                return value;
            }
            return "";
        },
        onChangeObject(val) {
            const isAll = val.includes("all");
            if (isAll) {
                this.editedItem.object = this.users.map((item) => {
                    return item.id;
                });
                this.users.shift();
                this.users.unshift({
                    name: "Bỏ chọn",
                    id: "removeAll",
                });
            }
            if (val.includes("removeAll")) {
                this.editedItem.object = [];
                this.users.shift();
                this.users.unshift({
                    name: "Tất cả",
                    id: "all",
                });
            }
        },
        async processGuiderCodeDelete(id) {
            this.loadingCreate = true
            const x = await apiClient.processGuiderCodeDelete(id)
            if (x) {
                this.getAllGuiderCode()
                this.loadingCreate = false
            }
        },
        editDetail() {
            if (Array.isArray(this.data) && this.data.length > 0) {
                const item = {...this.data[0]}
                this.editItem(item)
            }
        }

    },
    created() {
        this.getAllUser();
    },
});
